import React, { useEffect, useState } from 'react';
import {
    graphql,
    navigate,
    useStaticQuery,
    type WrapPageElementNodeArgs,
    type WrapPageElementBrowserArgs,
} from 'gatsby';

// Locals
import LangContext, { defaultLangEntry, LangContextType, LangItem } from 'lib/langContext';
import { IntlProvider } from 'react-intl';

require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/locale-data/en');
require('@formatjs/intl-relativetimeformat/locale-data/es');
require('@formatjs/intl-relativetimeformat/locale-data/ru');
require('@formatjs/intl-relativetimeformat/locale-data/fa');

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/en');
require('@formatjs/intl-pluralrules/locale-data/es');
require('@formatjs/intl-pluralrules/locale-data/ru');
require('@formatjs/intl-pluralrules/locale-data/fa');

// Algolia
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure as AlgoliaConfigure } from 'react-instantsearch';

type LangEntryGqlFull = RequiredNonNullable<Queries.SiteSiteMetadataLanguagesLangs>;

type SetDirProps = { rtl: boolean };
function SetDir({ rtl }: SetDirProps) {
    useEffect(() => {
        document.documentElement.setAttribute('dir', rtl ? 'rtl' : 'ltr');
    }, [rtl]);
    return null;
}

const noop = () => {};
const searchClient = algoliasearch(
    String(process.env.GATSBY_ALGOLIA_APP_ID),
    String(process.env.GATSBY_ALGOLIA_SEARCH_KEY),
);

function loadLocaleData(locale: string): Promise<Record<string, string>> {
    const map = r => r.default as unknown as Record<string, string>;
    switch (locale) {
        case 'es':
            return import('data/languages/messages-compiled/es.json').then(map);
        case 'fa':
            return import('data/languages/messages-compiled/fa.json').then(map);
        case 'ru':
            return import('data/languages/messages-compiled/ru.json').then(map);
        default:
            return import('data/languages/messages-compiled/en.json').then(map);
    }
}

type PageWrapperProps =
    | ({ children: React.ReactElement } & WrapPageElementNodeArgs['props'])
    | WrapPageElementBrowserArgs['props'];

export default function PageWrapper({ children, location }: PageWrapperProps) {
    const { pathname } = location;

    useEffect(() => {
        if (pathname === '/') navigate('/en', { replace: true });
    }, [pathname]);

    const data = useStaticQuery<Queries.RootDataQuery>(graphql`
        query RootData {
            site {
                siteMetadata {
                    languages {
                        langs {
                            key
                            label
                            isRTL
                        }
                    }
                }
            }
            allSitePage {
                nodes {
                    matchPath
                    path
                }
            }
        }
    `);

    const allPagesPaths = data.allSitePage.nodes.map(n => n.path.replace(/\/$/, ''));
    const langs = data.site?.siteMetadata?.languages?.langs as LangEntryGqlFull[];
    const [messages, setMessages] = useState<Record<string, string>>({});

    const langKeyFromUrl: string = pathname.split('/').filter(Boolean)[0];
    const currLangEntry = (langs.find(d => d.key === langKeyFromUrl) || defaultLangEntry) as LangEntryGqlFull;

    const currentSlug = pathname.replace(`/${langKeyFromUrl}/`, '/');
    const localizedLinks = allPagesPaths.filter(p => p.match(currentSlug));

    const contextValue: LangContextType = {
        langs: langs.map<LangItem>(({ key, label }) => ({
            label,
            langKey: key,
            selected: key === currLangEntry.key,
            link: `/${key}/`,
        })),
        default: defaultLangEntry,
        current: currLangEntry,
        localizedLinks: localizedLinks,
        messages,
    };

    useEffect(() => {
        loadLocaleData(currLangEntry.key).then(setMessages);
    }, [currLangEntry.key]);

    const indexName = `Pages_${currLangEntry.key}`;
    return (
        <IntlProvider locale={currLangEntry.key} onError={noop} onWarn={noop} messages={contextValue.messages}>
            <InstantSearch
                searchClient={searchClient}
                indexName={indexName}
                future={{ preserveSharedStateOnUnmount: true }}
                routing={{
                    // ?search=…
                    stateMapping: {
                        stateToRoute: uiState => ({ search: uiState[indexName].query }),
                        routeToState: routeState => ({ [indexName]: { query: routeState.search } }),
                    },
                }}
            >
                <AlgoliaConfigure analytics hitsPerPage={40} highlightPreTag="<mark>" highlightPostTag="</mark>" />
                <SetDir rtl={currLangEntry.isRTL} />
                <LangContext.Provider value={contextValue} children={children} />
            </InstantSearch>
        </IntlProvider>
    );
}
